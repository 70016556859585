import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-start">
            ©2020 1Law. All rights reserved. <span style={{color: "#0E2640", textDecoration: "none"}} >435-339-0657</span>
          </div>
          <div className="footer-end">
            Site by  <a href="https://tractusonline.com/" target="_blank" style={{color: "white", fontWeight: 800, textDecoration: "none"}}>Tractus</a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
